import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import {drag, D3DragEvent} from 'd3-drag'
import {select, pointer, Selection, selectAll, BaseType} from 'd3-selection'
import { SnackbarCloseReason, debounce } from "@mui/material";
import { zoom as Zoom, D3ZoomEvent, zoomIdentity, ZoomTransform , zoomTransform} from "d3-zoom";
import {
  delete_icon, copy, arrow
} from "../src/assets";
import { brush } from "d3-brush";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export type Result<T> = {
  data: T
}
export type AddNodeResponseData = 
  {
    data:
    {
      id:number,
      event_name:string,
      dewey_code:string,
      x_val:number,
      y_val:number,
      created_at:string,
      updated_at:string,
      helios_group_id:number,
      user_id: number,
      length:number,
      width:number
    },
  message:string
}
export type FilterResponse = {
  dewey_code_events: [{
    id: number,
    event_name: string,
    dewey_code: string,
    created_at:string,
    updated_at:string,
  }],
  message: string
}
export type Data = {
  id: number;
  event_name: string;
  dewey_code: string;
  x: number;
  y: number;
  width: number;
  height: number;
  created_at: string;
  updated_at: string;
}
export enum SaveState {
  ToAdd,
  ToDelete,
  ToUpdate,
  NoChange
}
export enum Mode {
  Pan,
  Selection
}
export interface IControlPoint {
  x: number;
  y: number;
}
export interface IBendData {
  distance: number,
  direction: string
}
export interface IArrow {
  id: number,
  start: {x: number, y: number},
  end: {x: number, y: number},
  startNodeId: number,
  endNodeId: number,
  save_state: SaveState,
  controlPoints: IControlPoint[],
  bendData: IBendData,
  count: number
}
export interface INode {
  id: number,
  x: number,
  y: number,
  width: number,
  height: number,
  name: string,
  event_name: string,
  dewey_code: string,
  save_state: SaveState,
  percentage: string,
}
// Customizable Area End

interface S {
  // Customizable Area Start
  svg?: Selection<SVGSVGElement, unknown, null, undefined>,
  sChartWidth: number,
  isAddingNode: boolean,
  isAddingArrow: boolean,
  arrowStart: {x: number, y: number}|null;
  arrowStartNodeId: number
  maxCount: number,
  nodes: INode[]
  unFilteredNodes: INode[],
  unFilteredArrows: IArrow[],
  arrows: IArrow[],
  isLoading: boolean,
  zoomLevel: number,
  eventTransform: {x: number, y: number},
  panDelta: {x: number, y: number},
  mode: Mode,
  isDragging: boolean,
  isBrushing: boolean,
  isCleared: boolean,
  isClicked: boolean,
  zoomStarted: boolean,
  searchValue: string,
  arrowLength: number,
  idCounter: number,
  draggedNode: INode | null,
  arrowHeadPos: {x: number, y: number},
  selectedNode: INode|null,
  selectedNodes: INode[],
  selectedArrow: IArrow|null,
  copiedNode: INode|null,
  copiedNodes: INode[],
  userToken: string,
  userEmail: string,
  zoomTransform: ZoomTransform | null,
  nodeGroups: Selection<BaseType | SVGGElement, INode, SVGGElement, unknown> | null,
  canEdit: boolean,
  isSuperUser: boolean,
  deletedNodes: INode[],
  deletedArrows: IArrow[],
  isSaved: boolean,
  showDropdown: boolean,
  mirrorSelectedOption: string[],
  selectedOption: string[],
  openErrorPrompt: boolean,
  errorMessage: string,
  openPrompt: boolean,
  isSaving: boolean,
  latestID: number,
  startSide: string,
  XYChange: {x: number, y: number},
  heliosGroupID: number,
  groupName: string,
  labels: string[],
  prevDragEvent: {
    d: INode,
    event: any
  } | undefined
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WhiteboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public chartRef3: React.RefObject<SVGSVGElement>;
  postNodeApiId = new Map<string, string>(); //api id and dewey_code
  postNodeId = '';
  updateNodeApiId = new Map<string, number>();
  postGroupApiID = '';
  deleteNodeApiId = new Map<string, number>();
  deleteArrowApiId = '';
  postArrowApiId = new Map<string, number>();
  updateArrowApiId = new Map<string, number>();
  postArrowDeweyCodesApiId = '';
  updateWhiteboardMetaDataApiId = '';
  getDataApiId = '';
  filterByLabelsApiId = '';
  searchDataApiId = '';
  getMetaDataApiId = '';
  getGroupsApiID = '';
  getLabelsApiID = '';
  getArrowCountApiID = new Map<string, number>();
  updatedIds = new Map<number, number>();
  arrowCounts = new Map<number, number>();
  getUserInfoAPIId = '';
  updateArrowDistance = (arrow: IArrow, XChange: number, YChange: number, isStart: boolean)=>{
    
      let distance = arrow.bendData.distance;
      switch(arrow.bendData.direction){
        case 'NE':
          case 'NW':
            distance = isStart ? distance + XChange : distance;
            break;
            case 'EN':
              case 'WN':
            distance = isStart ? distance : distance + XChange;
            break;
              case 'SE':
                case 'SW':
          distance = isStart? distance : distance+YChange;
          break;
          case 'ES':
                    case 'WS':
          distance = isStart? distance+YChange : distance;
                    break;

      }
      return distance;
  }
  handleDragEnd = (event: D3DragEvent<SVGSVGElement, unknown, INode>, _d: unknown)=>{
    if(this.state.isLoading) return;
    const mouseEvent = event.sourceEvent as MouseEvent;
          mouseEvent.stopImmediatePropagation()
    let data: INode = _d as INode;
    let {selectedNodes} = this.state;
    let prevDragEvent = this.state.prevDragEvent!;
    if(!prevDragEvent){
      prevDragEvent = {d: data, event: event}
    }
      if(!selectedNodes.find(node => node.id === data.id)){
        //just selecting 1
        selectedNodes = [data]
      }
      let updatedNodes = this.state.nodes;
      let updatedArrows = this.state.arrows;
      for(let d of selectedNodes){
        updatedNodes = this.onDragEndUpdateNodes(updatedNodes, d, event)
        updatedArrows = this.getUpdatedArrows(updatedArrows, event, prevDragEvent, d)
        d.x += event.x - prevDragEvent.event.x; d.y += event.y - prevDragEvent.event.y;        
      }
      this.setState({nodes: updatedNodes, arrows: updatedArrows, isSaved: false, prevDragEvent: undefined, isDragging: false}, ()=> {this.refresh();

      })
  }
  getUpdatedArrows = (updatedArrows: IArrow[], event: any, prevDragEvent: any, d: INode)=>{
    return updatedArrows.map((arrow: IArrow)=>{
      if(arrow.startNodeId === d.id) {
        const arr = this.state.svg!.select(`[arrowgroupid="${arrow.id}"]`)
      return {...arrow,bendData: {distance: this.updateArrowDistance(arrow, event.x - prevDragEvent.event.x, event.y -prevDragEvent.event.y, true), direction: arrow.bendData.direction}, start: {x: parseInt(arr.attr('startX')), y:parseInt(arr.attr('startY'))}, save_state:  arrow.save_state === SaveState.ToAdd ? SaveState.ToAdd : SaveState.ToUpdate} }
      else if(arrow.endNodeId === d.id){
        const arr = this.state.svg!.select(`[arrowgroupid="${arrow.id}"]`)
        return {...arrow, end: {x: parseInt(arr.attr('endX')), y:parseInt(arr.attr('endY'))}, bendData: {distance: this.updateArrowDistance(arrow, event.x - prevDragEvent.event.x, event.y -prevDragEvent.event.y, false), direction: arrow.bendData.direction}, save_state:  arrow.save_state === SaveState.ToAdd ? SaveState.ToAdd : SaveState.ToUpdate}}
        else return arrow })
  }
  onDragEndUpdateNodes = (nodes: INode[], d: INode, event: D3DragEvent<SVGSVGElement, unknown, INode>)=>{
    return nodes.map((node: INode)=>{
      let prevDragEvent = this.state.prevDragEvent!;
      if(!prevDragEvent){
        prevDragEvent = {d: d, event: event}
      }
      const nodeSelection = this.state.svg!.select(`[nodeid="${d.id}"]`)
      d = {...d, x: parseInt(nodeSelection.select('rect').attr('x')), y: parseInt(nodeSelection.select('rect').attr('y'))}

      if(node.id === d.id){return {...d, save_state:  node.save_state === SaveState.ToAdd ? SaveState.ToAdd : SaveState.ToUpdate};}
      // if(node.id === d.id){return {...node, x: node.x+event.x-prevDragEvent.event.x, y: node.y+event.y-prevDragEvent.event.y, save_state:  node.save_state === SaveState.ToAdd ? SaveState.ToAdd : SaveState.ToUpdate};}
      return node;
    })
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this); 

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    
    
    this.state = {
      // Customizable Area Start
      svg: undefined,
      sChartWidth: 1350,
      isAddingNode: false,
      nodes: [],
      unFilteredNodes: [],
      unFilteredArrows: [],
      isAddingArrow: false,
      arrowStart: null,
      arrowStartNodeId: 0,
      arrows: [],
      maxCount: 1,
      zoomLevel: 1,
      eventTransform: {x: 0, y: 0},
      panDelta: {x: 0, y: 0},
      mode: Mode.Pan,
      isDragging: false,
      isBrushing: false,
      isCleared: false,
      isClicked: false,
      zoomStarted: false,
      selectedOption: [],
      mirrorSelectedOption: [],
      showDropdown: false,
      arrowLength: 0,
      idCounter: 9999,
      draggedNode: null,
      arrowHeadPos: {x: 0, y: 0},
      selectedNode: null,
      selectedNodes: [],
      selectedArrow: null,
      copiedNode: null,
      copiedNodes: [],
      userToken: '',
      userEmail: '',
      searchValue: "",
      zoomTransform: null,
      nodeGroups: null,
      canEdit: false,
      isSuperUser: false,
      deletedArrows: [],
      deletedNodes: [],
      isSaved: true,
      openPrompt: false,
      openErrorPrompt: false,
      errorMessage: '',
      isSaving: false,
      latestID: 10000,
      isLoading: false,
      startSide: '',
      prevDragEvent: undefined,
      XYChange: {x:0, y:0},
      heliosGroupID: 2,
      groupName: "FINAL GROUP",
      labels: []
      // Customizable Area End
    };
    // Customizable Area Start
    
    this.chartRef3 = React.createRef()
    this.handleAddNodeClick = this.handleAddNodeClick.bind(this);
    this.handleAddArrowClick = this.handleAddArrowClick.bind(this);
    this.handleSvgClick = this.handleSvgClick.bind(this);
    this.updateArrows = this.updateArrows.bind(this)
  // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  getData = (groupID: number)=>{
    this.setState({isLoading: true})
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getDataApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupEvents + '?group_id=' + groupID
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  searchData = ()=>{
    if(this.state.searchValue === "") {
      this.getData(this.state.heliosGroupID)
      return;
    }
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.searchDataApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.search+"?query="+encodeURIComponent(this.state.searchValue)+"&group_id="+this.state.heliosGroupID
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    this.send(message)
  }
  getGroups = ()=>{
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getGroupsApiID = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroups
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  getArrowCount = (arrowID: number, startNodeCode: string, endNodeCode: string)=>{
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getArrowCountApiID.set(message.messageId, arrowID)
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getArrowCount + "?start_code="+startNodeCode+"&end_code="+endNodeCode
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  getLabels = ()=>{
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getLabelsApiID = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLabels
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  getMetaData = ()=>{
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getMetaDataApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWhiteBoardTransform
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  filterByLabels = (labels: string[])=>{
    if(labels.length <= 0 ){this.getData(this.state.heliosGroupID);return;}
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.filterByLabelsApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterByLables + '?labels=' + labels
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  postArrow = (parentId: number, childid: number, xs: number, ys: number, xe: number, ye: number, bendData: IBendData) => {
    const startNode = this.state.nodes.find(node => node.id === parentId)
    const endNode = this.state.nodes.find(node => node.id === childid)
    if(startNode && endNode) this.postArrowDeweyCodes(startNode.dewey_code, endNode.dewey_code, this.state.userEmail);
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.postArrowApiId.set(message.messageId, parentId)
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCreateDirection + '?parent_id='+parentId+'&child_id='+childid+'&xs='+xs+'&ys='+ys+'&xe='+xe+'&ye='+ye+'&direction='+bendData.direction+'&distance='+bendData.distance
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  postArrowDeweyCodes = (parentDewey: string, childDewey: string, email: string) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.postArrowDeweyCodesApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postNewArrowData + '?starting_dewey_code='+parentDewey+'&ending_dewey_code='+childDewey+'&email='+email
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  updateArrow = (parentId: number, childid: number, xs: number, ys: number, xe: number, ye: number, arrowId: number) => {
    const startNode = this.state.nodes.find(node => node.id === parentId)
    const endNode = this.state.nodes.find(node => node.id === childid)
    if(startNode && endNode) this.postArrowDeweyCodes(startNode.dewey_code, endNode.dewey_code, this.state.userEmail);
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.updateArrowApiId.set(message.messageId, arrowId)
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateHeliosDirection + '?parent_id='+parentId+'&child_id='+childid+'&xs='+xs+'&ys='+ys+'&xe='+xe+'&ye='+ye+'&direction_id='+arrowId
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  updateWhiteboardMetaData = (x: number, y: number, zoom: number) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.updateWhiteboardMetaDataApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateWhiteBoardTransform + '?x='+x+'&y='+y+'&zoom='+zoom
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  postNode = (eventName: string, deweyCode: string, x: number, y: number, heliosGroupId: number, length: number, width: number) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.postNodeApiId.set(message.messageId, deweyCode)
    this.postNodeId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postHeliosData + '?event_name='+ eventName + '&dewey_code=' + deweyCode + '&x_val=' + x + '&y_val=' + y + '&helios_group_id=' + heliosGroupId + '&length=' + length + '&width=' + width
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  postGroup = (groupName: string) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.postGroupApiID = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGroup + '?helios_group_name='+ groupName
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  updateNode = (eventName: string, deweyCode: string, x: number, y: number, heliosDataId: number, length: number, width: number) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.updateNodeApiId.set(message.messageId, heliosDataId)
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateHeliosData + '?event_name='+ eventName + '&dewey_code=' + deweyCode + '&x_val=' + x + '&y_val=' + y + '&helios_data_id=' + heliosDataId + '&length=' + length + '&width=' + width
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  deleteNode = (heliosDataId: number) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deleteNodeApiId.set(message.messageId, heliosDataId)
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteHeliosData + '?helios_data_id=' + heliosDataId
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  deleteArrow = (arrow_id: number) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deleteArrowApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteHeliosDirection + '?direction_id=' + arrow_id
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    
    
    this.send(message)
    
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleGetGroupsAPIResponse(apiRequestCallId, responseJson)
      this.handleGetLabelsAPIResponse(apiRequestCallId, responseJson)
      this.handlePostGroupAPIResponse(apiRequestCallId, responseJson)
      this.handleGetDataAPIResponse(apiRequestCallId, responseJson)
      this.handleGetSearchDataAPIResponse(apiRequestCallId, responseJson)
      this.handleGetMetaDataAPIResponse(apiRequestCallId, responseJson)
      this.handleAddNodeAPIResponse(apiRequestCallId, responseJson)
      this.handleDeleteNodeAPIResponse(apiRequestCallId, responseJson)
      this.handleGetUserInfoAPIResponse(apiRequestCallId, responseJson)
      this.handleFilterByLabelsAPIResponse(apiRequestCallId, responseJson)
      this.handleAddArrowAPIResponse(apiRequestCallId, responseJson)
      this.handleUpdateArrowAPIResponse(apiRequestCallId, responseJson)
      this.handleUpdateNodeAPIResponse(apiRequestCallId, responseJson)
      this.handleGetArrowCountAPIResponse(apiRequestCallId, responseJson)
    }
  }
  handleGetUserInfoAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(apiRequestCallId === this.getUserInfoAPIId){
      this.setState({isSuperUser: responseJson.data.attributes.helios_super_group, userEmail: responseJson.data.attributes.email, userToken: responseJson.data.attributes.session_token}, ()=>{this.initializeZoom(); this.getLabels();this.getGroups();this.getMetaData()})
    }
  }
  handleGetDataAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(apiRequestCallId === this.getDataApiId){
      let data = responseJson.data.data.attributes.helios_events;
      const nodes: INode[] = data.map((d: any)=>({
        id: d.id,
        x: d.x_val,
        y: d.y_val,
        width: d.width ? d.width : 100,
        height: d.length ? d.length: 100,
        name: d.event_name,
        event_name: d.event_name,
        dewey_code: d.dewey_code,
        save_state: SaveState.NoChange,
        percentage: "0.0%"
      }))
      this.handleGetArrows(data, nodes)

      }
    }
    handleGetSearchDataAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
      if(apiRequestCallId === this.searchDataApiId && responseJson){
        
        let data = responseJson.data;
        if(!data){

          this.parseApiErrorResponse(responseJson);
          this.toastError('Not found!')
          this.getData(this.state.heliosGroupID)
          return;
        }
        
        const nodes: INode[] = data.map((d:any)=>{return {
          id: d.id,
          x: d.x_val,
          y: d.y_val,
          width: d.width ? d.width : 100,
          height: d.length ? d.length: 100,
          name: d.event_name,
          event_name: d.event_name,
          dewey_code: d.dewey_code,
          save_state: SaveState.NoChange
        }})
        
        
        const arrows: IArrow[] = []
      this.setState({nodes: nodes, arrows: arrows}, ()=>{this.refresh()})
  
        }
      }
    handleFilterByLabelsAPIResponse = (apiRequestCallId: string, responseJson: FilterResponse)=>{
      if(apiRequestCallId === this.filterByLabelsApiId){
        let data = responseJson.dewey_code_events;
        if(data){
          const nodes = this.state.unFilteredNodes; 
          const filteredNodes = nodes.filter(node => data.some(d => d.dewey_code === node.dewey_code));
          if(filteredNodes.length === 0) this.toastError("No results found!")
          this.setState({nodes: filteredNodes, unFilteredArrows: this.state.arrows, arrows: []}, this.refresh)
        }else{
          this.toastError("No results found!")
        }
      }
    }
    handleGetGroupsAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
      if(apiRequestCallId === this.getGroupsApiID){
        const group = responseJson.data.find((item: any) => item.helios_group_name === this.state.groupName);
        if(!group){
          this.postGroup(this.state.groupName)
        }else{
          this.setState({heliosGroupID: group.id}, ()=>this.getData(group.id))
        }
        }
      }
      handleGetLabelsAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
        if(apiRequestCallId === this.getLabelsApiID){
            this.setState({labels: responseJson})
          }
        }
  handlePostGroupAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(apiRequestCallId === this.postGroupApiID){
      if(responseJson.data.id){
        this.setState({heliosGroupID: responseJson.data.id}, ()=>{this.getData(responseJson.data.id)})
      }
    }
  }
    handleGetMetaDataAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
      if(apiRequestCallId === this.getMetaDataApiId){
        let data = responseJson.data;
        let newZoomTransform = new ZoomTransform(Math.max(data.zoom, 0.1), Math.max(data.x, 1), Math.max(data.y, 1));
        select(this.chartRef3.current).call(Zoom().transform as any, newZoomTransform);
        this.setState({zoomTransform: newZoomTransform}, ()=>{this.adjustZoom(99);})
      
        }
      }
     calculateArrowPathOnDrag = (arrow: IArrow, isStart: boolean, XChange: number, YChange: number)=>{
      let {start, end, controlPoints, bendData } = arrow;
      let { distance, direction } = bendData;
      let controlPointsPath = "";
      switch(direction){
        case 'EW':
        case 'WE':
          controlPoints = [{x:  distance, y: arrow.start.y}, {x:  distance, y: arrow.end.y}]
        break;
        case 'NW'://has to update with drag
        case 'NE':
          controlPoints = [{x:  isStart?distance+XChange:distance, y: arrow.end.y}]
          break;
          case 'WN':
            case 'EN':
              controlPoints = [{x: isStart? distance: distance+XChange, y: arrow.start.y}]
              break;
              
              case 'NN':
                case 'SS':
                  controlPoints = [{x: arrow.start.x, y:  distance}, {x:  arrow.end.x, y: distance}]
                  break;
                  case 'EE':
                    case 'WW':
                      controlPoints = [{x: distance, y:  arrow.start.y}, {x:  distance, y: arrow.end.y}]
                      break;
                      case 'NS':
                        case 'SN':
                          controlPoints = [{x:  arrow.start.x, y: distance}, {x:  arrow.end.x, y: distance}]
                          break;
        case 'SE':
        case 'SW':
          controlPoints = [{x:  arrow.start.x, y: isStart? distance: distance+YChange}]
          break;
          case 'ES':
        case 'WS':
          controlPoints = [{x:  arrow.end.x, y: isStart?distance+YChange:distance}]
          break;
          
      }    
      controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
      return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
    }
    onNodeDrag = (event: D3DragEvent<SVGSVGElement, unknown, INode>, _d: unknown) => {
      if(this.state.isLoading) return;
      const mouseEvent = event.sourceEvent as MouseEvent;
      mouseEvent.stopImmediatePropagation()
          
      let data = _d as INode
      let {selectedNodes} = this.state;
      let prevDragEvent = this.state.prevDragEvent!
      if(!prevDragEvent){
        prevDragEvent = {d: data, event: event}
      }
      if(!selectedNodes.find(node => node.id === data.id)){
        //just selecting 1
        selectedNodes = [data]
      }
      const {svg} = this.state;
      for(let d of selectedNodes){
        const nodeSelection = svg!.select(`[nodeid="${d.id}"]`)

        const rect = nodeSelection.select('rect')
        d = {...d, x: parseInt(rect.attr('x')), y: parseInt(rect.attr('y'))}
        rect.attr('x', d.x+event.x - prevDragEvent.event.x).attr('y', d.y+event.y - prevDragEvent.event.y)
      const rectSelection = nodeSelection.select('rect')
      const textY = rectSelection.empty() === false ? (+rectSelection.attr('height')- +nodeSelection.select('.name').attr('textHeight')) / 2 : 0;
      nodeSelection.select('.name').attr('x', d.x+event.x - prevDragEvent.event.x+d.width/2).attr('y', d.y+event.y - prevDragEvent.event.y+textY).selectChildren('tspan').each(function (_d, i){ select(this).attr('x', d.x+event.x - prevDragEvent.event.x+d.width/2).attr('y', d.y+event.y -prevDragEvent.event.y+textY).attr('dy', `${1.1*i}em`)})
      nodeSelection.select('.code').attr('x', d.x+event.x - prevDragEvent.event.x+d.width).attr('y', d.y+event.y - prevDragEvent.event.y-15)
      nodeSelection.select('.percentage').attr('x', d.x+event.x - prevDragEvent.event.x+d.width).attr('y', d.y+event.y - prevDragEvent.event.y+d.height+15)
      
      this.state.svg!.selectAll(`[startnode="${d.id}"]`).each( (arr)=>{
        let arrow = arr as IArrow
        const arrowGroup = this.state.svg!.select(`[arrowgroupid="${arrow.id}"]`)
        arrow = this.getUpdatedXandY(arrowGroup, arrow)

        arrowGroup.selectAll('.control-point').attr('visibility', 'hidden')
        
  
        arrowGroup.select('path').attr('d', this.calculateArrowPathOnDrag({...arrow, start: {x: arrow.start.x+ event.x -prevDragEvent.event.x,y: arrow.start.y+event.y - prevDragEvent.event.y}}, true, event.x -prevDragEvent.event.x, event.y-prevDragEvent.event.y).path)
        arrowGroup.attr('startX', arrow.start.x+ event.x -prevDragEvent.event.x)
        arrowGroup.attr('startY', arrow.start.y+event.y - prevDragEvent.event.y)
      })
      this.state.svg!.selectAll(`[endnode="${d.id}"]`).each( (arr)=>{
        let arrow = arr as IArrow
        const arrowGroup = this.state.svg!.select(`[arrowgroupid="${arrow.id}"]`)
        arrow = this.getUpdatedXandY(arrowGroup, arrow)

        arrowGroup.selectAll('.control-point').attr('visibility', 'hidden')
      
       
        arrowGroup.select('path').attr('d', this.calculateArrowPathOnDrag({...arrow, end: {x: arrow.end.x+ event.x -prevDragEvent.event.x,y: arrow.end.y+event.y - prevDragEvent.event.y}}, false, event.x-d.x, event.y-d.y).path)
        arrowGroup.attr('endX', arrow.end.x+ event.x -prevDragEvent.event.x)
        arrowGroup.attr('endY', arrow.end.y+event.y - prevDragEvent.event.y)
      })
      this.setState({prevDragEvent: {d:data, event: event}})
      
      }

      
   }
   getUpdatedXandY = (arrowGroup: Selection<BaseType, unknown, null, undefined>, arrow: IArrow)=>{
    return {...arrow, start: {
      x: parseInt(arrowGroup.attr('startX')),
      y:parseInt(arrowGroup.attr('startY'))
    }, end: {
      x: parseInt(arrowGroup.attr('endX')),
      y: parseInt(arrowGroup.attr('endY'))
    }}
   }
   onNodeDragStart = (event: D3DragEvent<SVGSVGElement, unknown, INode>, _d: unknown)=>{
    if(this.state.selectedNodes.length === 0)this.selectNode(_d as INode);
    const mouseEvent = event.sourceEvent as MouseEvent;
    mouseEvent.stopImmediatePropagation()
    this.setState({isDragging: true})
  }
    dragHandler = drag()
        .on('start', this.onNodeDragStart)
        .on('drag',  this.onNodeDrag).on('end', this.handleDragEnd)
    handleGetArrows = (data: any, nodes: INode[])=>{
      const arrows: IArrow[] = [];
      //create a new Map to store the percentage
      const percentages = new Map()
      for(let d of data){
        if(d.parent_events.length > 0){
          for(let arrow_event of d.parent_events){
            let parentNode = nodes.find((val:any) => val.id === arrow_event.parent_id);
            if(parentNode){
              let arrow: IArrow = {
                id: arrow_event.id,
                startNodeId: arrow_event.parent_id,
                endNodeId: d.id,
                start: {x: arrow_event.xs, y: arrow_event.ys},
                end: {x: arrow_event.xe, y: arrow_event.ye},
                save_state: SaveState.NoChange,
                controlPoints: [{x: Math.abs(arrow_event.xs - arrow_event.xe) / 2 + arrow_event.xs, y: arrow_event.ys}, {x: Math.abs(arrow_event.xs - arrow_event.xe) / 2 + arrow_event.xs, y: arrow_event.ye}],
                bendData: {distance: parseInt(arrow_event.distance), direction: arrow_event.direction},
                count: 0
              }
              percentages.set(arrow_event.parent_id, arrow_event.percentage +'%')
              arrows.push(arrow);

            }
          }
      }
      
      
    }
    //update the nodes
    const updatedNodes = nodes.map(node=>{
      
      let percentage = percentages.get(node.id)
      if(!percentage){
        percentage = "0.0%";
      }
      return {...node, percentage: percentage}
    })
    this.setState({nodes: updatedNodes, unFilteredNodes: updatedNodes, arrows: arrows}, ()=>{this.fetchCounts(arrows, nodes)})

    }

    fetchCounts = (arrows: IArrow[], nodes: INode[])=>{
      if(arrows.length === 0) {
        this.setState({isLoading: false}, this.refresh)
        return;
      }
      for(let arrow of arrows){
        const startNode = nodes.find(node => node.id == arrow.startNodeId)
        const endNode = nodes.find(node => node.id == arrow.endNodeId)
        if(startNode && endNode){
          this.getArrowCount(arrow.id, startNode.dewey_code, endNode.dewey_code);
        }
      }
    }
  handleKeyDown = (event: KeyboardEvent)=>{
    if(event.ctrlKey || event.metaKey){
      const key = event.key.toLowerCase();
      switch(key){
        case 'c':
          this.handleCopyNode();
          break;
        case 'v':
          this.handlePasteNode();
      }
    }
  }
  getEnd = (direction: string, x: number, y: number, count: number, maxCount: number): {x: number, y: number}=>{
    switch(direction){
      case 'SE':
      case 'NE':
      case 'WE':
        case 'EE':
          return {x: x - Math.max(((Math.max(count, 1))/maxCount)*5, 1), y: y}
        case 'SW':
          case 'NW':
          case 'WW':
            case 'EW':
          return {x: x + Math.max(((Math.max(count, 1))/maxCount)*5, 1), y: y}
              case 'NN':
          case 'SN':
          case 'WN':
            case 'EN':
          return {x: x, y: y + Math.max(((Math.max(count, 1))/maxCount)*5, 1)}
              case 'NS':
          case 'SS':
          case 'WS':
            case 'ES':
          return {x: x, y: y - Math.max(((Math.max(count, 1))/maxCount)*5, 1)}
              default: 
              return {x: x, y: y}
    }
  }
  handleBeforeUnload = (event: any)=>{
    if (!this.state.isSaved && this.state.isSaving) {
      event.preventDefault()
    }
  }
  getUserInfo(token: string) {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserInfoAPIId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_user/user_info`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  initializeUser = ()=>{
    const localStorageUserToken = localStorage.getItem("userInfo")
    const parseLocalStorage = JSON.parse(localStorageUserToken ?? "{}") || {}
    this.getUserInfo(parseLocalStorage.session_token)
    this.setState({userToken: parseLocalStorage.session_token})
  }
  initializeZoom = ()=>{
    if(this.state.isSuperUser){
      if(this.chartRef3.current) {
        
        const svg = select(this.chartRef3.current);
        const node = svg.node();
        if(node !== null){
          // if(this.state.mode === Mode.Selection){
          //   const zoom = Zoom<SVGSVGElement, unknown>().scaleExtent([0.1, 10]).on('zoom',  null );
          //   select(this.chartRef3.current).call(zoom);
          // }
          const zoom = Zoom<SVGSVGElement, unknown>().scaleExtent([0.1, 10]).filter((event)=> {
            return this.state.mode === Mode.Pan;
          }).on('start', ()=>{
            this.setState({zoomStarted: true})
          }).on('zoom', this.handleZoom).on('end', ()=>{
            this.setState({zoomStarted: false})
          });
          select(this.chartRef3.current).call(zoom);
        }
      }

    }
  }

  resetZoom = ()=>{
    if(this.chartRef3.current){
      const svgElement = select(this.chartRef3.current);
      const node = svgElement.node();

      const zoom = Zoom<SVGSVGElement, unknown>().scaleExtent([0.1, 10]).filter((event)=> {
        return this.state.mode === Mode.Pan;
      }).on('start', ()=>{
        this.setState({zoomStarted: true})
      }).on('zoom', this.handleZoom).on('end', ()=>{
        this.setState({zoomStarted: false})
      });

      svgElement.transition().duration(500).call(zoom.scaleTo, 1).transition().call(zoom.translateTo, 0.5 * node!.clientWidth, 0.5 * node!.clientHeight);
      this.setState({zoomLevel: 1});
    }
  }
  
  handleZoom = (event: D3ZoomEvent<SVGSVGElement, unknown>)=>{
    if(this.state.zoomStarted && this.state.svg){
      if(event.sourceEvent && event.sourceEvent.target === this.state.svg) this.removeDeleteButton()
      switch(this.state.mode){
        case Mode.Pan:
          this.state.svg.select('g').attr('transform', `translate(${event.transform.x}, ${event.transform.y}) scale(${event.transform.k})`);
            this.setState({zoomLevel: event.transform.k, zoomTransform: new ZoomTransform(Math.max(event.transform.k, 0.1), Math.max(event.transform.x, 1), Math.max(event.transform.y, 1)), eventTransform: event.transform})
        break;
        case Mode.Selection:

          let zoomTransform = this.state.zoomTransform;
          if(event.sourceEvent && event.sourceEvent.type === 'wheel'){
            const stransform = {x: this.state.zoomTransform!.x, y: this.state.zoomTransform!.y}
            this.state.svg.select('g').attr('transform', event.transform.toString());
            zoomTransform = new ZoomTransform(Math.max(event.transform.k, 0.1), Math.max(event.transform.x, 1), Math.max(event.transform.y, 1))

          }
          this.setState({zoomLevel: event.transform.k, eventTransform: event.transform, zoomTransform: zoomTransform})
        break;
      }

      
    }
  }

  zoomIn = ()=>{
    this.adjustZoom(1.1);
  }
  zoomOut = ()=>{
    this.adjustZoom(0.9);
  }
  adjustZoom = (factor: number)=>{
    this.removeDeleteButton()
    if(this.chartRef3.current){
      const svgElement = select(this.chartRef3.current);
      const {zoomLevel, zoomTransform} = this.state;
      const newZoomLevel = factor === 99 ? zoomTransform!.k : zoomLevel * factor;

      const zoom = Zoom<SVGSVGElement, unknown>().scaleExtent([0.1, 10]).filter((event)=> {
        return this.state.mode === Mode.Pan;
      }).on('start', ()=>{
        this.setState({zoomStarted: true})
      }).on('zoom', this.handleZoom).on('end', ()=>{
        this.setState({zoomStarted: false})
      });

      svgElement.transition().duration(500).call(zoom.scaleTo, newZoomLevel);
      this.setState({zoomLevel: newZoomLevel});
    }
  }
  
  handleCopyNode =()=>{
    const {selectedNode, selectedNodes}= this.state;
    this.removeDeleteButton()
    if(selectedNodes.length > 0){
      this.setState({copiedNodes: selectedNodes});
    }else if(selectedNode){
      this.setState({copiedNode: selectedNode});
    }
  }

  handlePasteNode = ()=>{
    const {copiedNode, nodes, copiedNodes} = this.state;
    if(copiedNodes.length > 0) {
      let newNodes: INode[] = [];
      for(let cNode of copiedNodes){
        const newNode = {...cNode, id: Date.now(), x: cNode.x+50, y: cNode.y+50, save_state: SaveState.ToAdd};
        if(this.checkDuplicateNodes([newNode], this.state.nodes)){
          this.toastError(`Dewey Code ${newNode.dewey_code} already exists. Make sure to change it.`)
        }
        newNodes.push(newNode)
      }
      this.setState((prev)=>({nodes: [...nodes, ...newNodes], selectedNodes: newNodes, copiedNodes: newNodes, idCounter: prev.idCounter+1}), ()=>{this.refresh()})
    }else if(copiedNode){
      const newNode = {...copiedNode, id: Date.now(), x: copiedNode.x+50, y: copiedNode.y+50, save_state: SaveState.ToAdd};
      if(this.checkDuplicateNodes([newNode], this.state.nodes)){
        this.toastError(`Dewey Code ${newNode.dewey_code} already exists. Make sure to change it.`)
      }
      this.setState((prev)=>({nodes: [...nodes, newNode], selectedNode: newNode, copiedNode: newNode, idCounter: prev.idCounter+1}), ()=>{this.refresh()})
    }
  }

  toastError = (message: string)=>{
    this.setState({openErrorPrompt: true, errorMessage: message})
  }
  checkDuplicateNodes = (nodesToAdd: INode[], oldNodes: INode[])=>{
    const existingDeweyCodes = new Set(oldNodes.map(item => item.dewey_code))
    return nodesToAdd.some(item => existingDeweyCodes.has(item.dewey_code))
  }
  handleAddNodes = (nodesToAdd: INode[], oldNodes: INode[], heliosGroupId: number) => {
    const dupeCount = new Map();
    nodesToAdd.forEach(node => {
      if(dupeCount.has(node.dewey_code)){
        dupeCount.set(node.dewey_code, dupeCount.get(node.dewey_code)+1)
      }else{
        dupeCount.set(node.dewey_code, 1)
      }
    });


    if(Array.from(dupeCount.values()).some(count => count > 1) || this.checkDuplicateNodes(nodesToAdd, oldNodes)){
      this.toastError('Save Failed. Duplicate Nodes found')
      this.setState({isSaving: false});
      return false;
    }
    for(let i = 0; i < nodesToAdd.length; i++){
      const node = nodesToAdd[i]
      this.postNode(node.event_name, node.dewey_code, node.x, node.y, heliosGroupId, node.height, node.width)
    }
    return true;
  }
  checkSaveState = ()=>{
    if(this.updateArrowApiId.size === 0 && this.updateNodeApiId.size === 0 && this.postArrowApiId.size === 0) this.setState({isSaving: false, openPrompt: true, isSaved: true}, ()=>{this.getData(this.state.heliosGroupID)})
  }
  handleAddArrowAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(this.postArrowApiId.has(apiRequestCallId)){
      this.postArrowApiId.delete(apiRequestCallId);
      if(this.postArrowApiId.size === 0) this.checkSaveState()

    }
  }  
  
  handleUpdateArrowAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(this.updateArrowApiId.has(apiRequestCallId)){
      this.updateArrowApiId.delete(apiRequestCallId);
      if(this.updateArrowApiId.size === 0) this.checkSaveState()
    }
  }  
  handleUpdateNodeAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(this.updateNodeApiId.has(apiRequestCallId)){
      this.updateNodeApiId.delete(apiRequestCallId);
      if(this.updateNodeApiId.size === 0) this.checkSaveState()
    }
  }  
  handleGetArrowCountAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
      const arrowId = this.getArrowCountApiID.get(apiRequestCallId);
      //set the count in the map
      if(arrowId){
        const count = responseJson.count;
        if(count){
          this.arrowCounts.set(arrowId, responseJson.count);

        }else{
          this.arrowCounts.set(arrowId, 0);
        }
        this.getArrowCountApiID.delete(apiRequestCallId)
      if(this.getArrowCountApiID.size <= 0){
          //process and save the arrows with counts, then refresh, remove the loading state
          const maxCount = this.findHighestValue(this.arrowCounts);
          this.setState((prev)=>({isLoading: false, maxCount: maxCount, arrows: prev.arrows.map((arrow: IArrow)=>{
            return {...arrow, end: this.getEnd(arrow.bendData.direction, arrow.end.x, arrow.end.y, this.arrowCounts.get(arrow.id)!, maxCount), count: this.arrowCounts.get(arrow.id)!}
          })}), this.refresh)
        }
      }
    
    
  }
  findHighestValue = (counts: Map<number, number>)=>{
    let max = -Infinity;
    for(let count of counts.values()){
      if(count > max) max = count;
    }
    return Math.max(1,max);
  }
  handleAddNodeAPIResponse = (apiRequestCallId: string, responseJson: AddNodeResponseData)=>{
    if(this.postNodeApiId.has(apiRequestCallId)){
      const nodeDeweyCode = this.postNodeApiId.get(apiRequestCallId);
      const {nodes, arrows} = this.state;
      const newNodeId = nodes.find(node => node.dewey_code === nodeDeweyCode)?.id;
      this.updatedIds.set(newNodeId!, responseJson.data.id)
      const updatedNodes = nodes.map(node => node.dewey_code === nodeDeweyCode ? {...node, id: responseJson.data.id} : node )
      this.setState({nodes: updatedNodes}, ()=>{
        this.postNodeApiId.delete(apiRequestCallId);
        //if no more api response to wait, we call save arrows
        if(this.postNodeApiId.size === 0){
          this.handleAddArrows();
        }
      })
    }
  }

  handleDeleteNodeAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(this.deleteNodeApiId.has(apiRequestCallId)){
      this.deleteNodeApiId.delete(apiRequestCallId);
      //if no more api response to wait, we call save nodes
      if(this.deleteNodeApiId.size === 0){
        this.setState({deletedNodes: []}, this.initSaveNodes)

      }
      
    }
  }
  initSaveNodes = ()=>{
    const {nodes} = this.state;
    const nodesToAdd: INode[] = nodes.filter(node => node.save_state === SaveState.ToAdd)
    const nodesToUpdate: INode[] = nodes.filter(node => node.save_state === SaveState.ToUpdate)
    const nodesLeft: INode[] = nodes.filter(node => node.save_state === SaveState.NoChange)
    if(nodesToAdd.length === 0){
      this.handleAddArrows()
    }
    this.handleAddNodes(nodesToAdd, [...nodesLeft, ...nodesToUpdate], this.state.heliosGroupID)
  }
  handleAddArrows = ()=>{
    const { nodes, arrows, deletedArrows} = this.state;
    //add arrows
    const arrowsToAdd: IArrow[] = this.state.arrows.filter(arrow => arrow.save_state === SaveState.ToAdd)
    const arrowsToUpdate: IArrow[] = arrows.filter(arrow => arrow.save_state === SaveState.ToUpdate)
    const arrowsLeft: IArrow[] = arrows.filter(arrow => arrow.save_state === SaveState.NoChange)
    const nodesToAdd: INode[] = nodes.filter(node => node.save_state === SaveState.ToAdd)
    const nodesToUpdate: INode[] = nodes.filter(node => node.save_state === SaveState.ToUpdate)
    const nodesLeft: INode[] = nodes.filter(node => node.save_state === SaveState.NoChange)
    for(let arrow of arrowsToAdd){
      this.postArrow(this.updatedIds.get(arrow.startNodeId) ? this.updatedIds.get(arrow.startNodeId)! : arrow.startNodeId, this.updatedIds.get(arrow.endNodeId) ? this.updatedIds.get(arrow.endNodeId)! : arrow.endNodeId, arrow.start.x, arrow.start.y, arrow.end.x, arrow.end.y, arrow.bendData);
    }
    
    //update arrows
    for(let i = 0; i < arrowsToUpdate.length; i++){
      const arrow = arrowsToUpdate[i];
      this.updateArrow(arrow.startNodeId, arrow.endNodeId, arrow.start.x, arrow.start.y, arrow.end.x, arrow.end.y, arrow.id)
    }
    //delete arrows
    for(let i = 0; i < deletedArrows.length; i++){
      const arrow = deletedArrows[i];
      this.deleteArrow(arrow.id);
    }
    //update nodes 
    for(let i = 0; i < nodesToUpdate.length; i++){
      const node = nodesToUpdate[i];
      this.updateNode(node.event_name, node.dewey_code, node.x, node.y, node.id,node.height, node.width)
    }
    
    //update state for nodes and arrows
    const updatedToAddNodes: INode[] = nodesToAdd.map(node=>{return ({...node, save_state: SaveState.NoChange})})
    const updatedToUpdateNodes: INode[] = nodesToUpdate.map(node=>{return ({...node, save_state: SaveState.NoChange})})
    const updatedToAddArrows: IArrow[] = arrowsToAdd.map(arrow=>{return ({...arrow, save_state: SaveState.NoChange, startNodeId: this.updatedIds.get(arrow.startNodeId) ? this.updatedIds.get(arrow.startNodeId)! : arrow.startNodeId, endNodeId: this.updatedIds.get(arrow.endNodeId) ? this.updatedIds.get(arrow.endNodeId)! : arrow.endNodeId })})
    const updatedToUpdateArrows: IArrow[] = arrowsToUpdate.map(arrow=>{return ({...arrow, save_state: SaveState.NoChange})})
    this.setState({  deletedArrows: [], nodes: [...nodesLeft, ...updatedToAddNodes, ...updatedToUpdateNodes],arrows: [...arrowsLeft, ...updatedToUpdateArrows, ...updatedToAddArrows], canEdit: false,  isSaved: true}, ()=>{this.checkSaveState();this.refresh()})
    
  }
  

  
  handleSave = ()=>{
    this.setState({isSaving: true, selectedNode: null, selectedArrow: null, selectedNodes: []}, ()=>{
    this.removeDeleteButton()
    this.handlePanMode()
    const zoomTransform = this.state.zoomTransform;
    if(zoomTransform)
    this.updateWhiteboardMetaData(zoomTransform.x,zoomTransform.y,zoomTransform.k)
    
    const {deletedNodes}= this.state;
    if(deletedNodes.length === 0){
      this.initSaveNodes()
    }
    //delete nodes 
    for(let i = 0; i < deletedNodes.length; i++){
      const node = deletedNodes[i];
      this.deleteNode(node.id);
    }

    

    
    
    //move this somewhere
    
    
    
    })
    
  }
  handleClosePrompt = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({openPrompt: false});
  };
  handleCloseErrorPrompt = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({openErrorPrompt: false});
  };
  handleDelete = ()=>{
    if(this.state.selectedNode){
      let {arrows,selectedNode} = this.state;
      let toDeleteArrows = arrows.filter(arrow => arrow.startNodeId === selectedNode.id && arrow.save_state !== SaveState.ToAdd)
      let toDeleteArrows2 = arrows.filter(arrow => arrow.endNodeId === selectedNode.id && arrow.save_state !== SaveState.ToAdd)
      let tempArrows = arrows.filter(arrow => arrow.startNodeId !=selectedNode.id)
      arrows = tempArrows.filter(arrow => arrow.endNodeId !=selectedNode.id)
      this.setState((prev)=>({
        isSaved: false, deletedNodes: [...prev.deletedNodes, prev.selectedNode as INode], deletedArrows: [...prev.deletedArrows, ...toDeleteArrows, ...toDeleteArrows2], nodes: prev.nodes.filter(node => prev.selectedNode && node.id !== prev.selectedNode.id), selectedNode: null, arrows: arrows
      }), ()=>{this.removeDeleteButton(); this.refresh()})
    }else if(this.state.selectedNodes.length > 0){
      let {arrows} = this.state;
      let toDeleteArrows: IArrow[] = [];
      let toDeleteArrows2: IArrow[] = [];
      for(let selectedNode of this.state.selectedNodes){
        let toDeleteArr = arrows.filter(arrow => arrow.startNodeId === selectedNode.id && arrow.save_state !== SaveState.ToAdd)
        let toDeleteArr2 = arrows.filter(arrow => arrow.endNodeId === selectedNode.id && arrow.save_state !== SaveState.ToAdd)
        let tempArrows = arrows.filter(arrow => arrow.startNodeId !=selectedNode.id)
        arrows = tempArrows.filter(arrow => arrow.endNodeId !=selectedNode.id)
        toDeleteArrows = [...toDeleteArrows, ...toDeleteArr]
        toDeleteArrows2 = [...toDeleteArrows2, ...toDeleteArr2]
      }
      const idsInArr2 = new Set(this.state.selectedNodes.map(node => node.id));
      const nodesLeft = this.state.nodes.filter(node => !idsInArr2.has(node.id));
      this.setState((prev)=>({
        isSaved: false, deletedNodes: [...prev.deletedNodes, ...(prev.selectedNodes as INode[])], deletedArrows: [...prev.deletedArrows, ...toDeleteArrows, ...toDeleteArrows2], nodes: nodesLeft, selectedNodes: [], arrows: arrows
      }), ()=>{this.removeDeleteButton(); this.refresh()})
    }else if(this.state.selectedArrow){
      this.setState((prev)=>({
        isSaved: false, deletedArrows: [...prev.deletedArrows, prev.selectedArrow as IArrow], arrows: prev.arrows.filter(arrow => arrow !== prev.selectedArrow), selectedArrow: null
      }), ()=>{this.removeDeleteButton(); this.refresh()})
    }
  }
  removeDeleteButton = ()=>{
    if(document.body.querySelector('.delete-button') !== null) document.body.removeChild(document.body.querySelector('.delete-button')!);
  }
  removeEditInput = ()=>{
    if(document.body.querySelector('.edit-input-field') !== null) document.body.removeChild(document.body.querySelector('.edit-input-field')!);
  }
  updateArrows = (d: INode, XChange: number, YChange: number)=>{
      let arrowX = d.x;
      let arrowY = d.y;
      const newArrowPos = {x: arrowX, y: arrowY}
      arrowX += XChange;
      arrowY += YChange;
      this.calculateArrowLength(d)
      newArrowPos.x = arrowX;
      newArrowPos.y = arrowY;
      this.calculateArrowHeadPosition(d, newArrowPos.x, newArrowPos.y);
  }
  handleNavigateWhiteboard = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), 'Analytics');
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(to);
  }
  drawDragChart = (svgElement: SVGSVGElement) => {
    if(!this.state.isClicked) this.setState({isClicked: true})
    if (svgElement === null) return;
    const svg = select(svgElement);

    this.setState({svg: svg});
    
    const draggableNodes = this.state.nodes;
    this.setState({nodes: draggableNodes})
    this.drawDraggableNodes(svg, draggableNodes, "draggable");
  }

  drawDraggableNodes = (
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    nodes: INode[] | Iterable<INode>,
    className: string
  ) => {
    
    const g = svg.select('g')
    g.selectAll(`.${className}`).remove();

    const group = g.append('g').attr('class', className);

    

    const nodeGroups = group
    .selectAll('g.node-group')
    .data(nodes)
    .join('g')
    .attr('class', 'node-group')
    .attr('nodeid', d=>d.id)

    

    

    
    
    nodeGroups.append('rect')
    .attr('class', 'nodePart')
    .attr('x', d =>  d.x)
    .attr('y', d =>  d.y)
    .attr('width', d =>  d.width)
    .attr('height', d =>  d.height)
    .attr('fill', 'white')
    .attr('stroke', '#6200EA')
    .style('stroke-width', (d)=>this.state.selectedNodes.find(node => node.id === d.id) ?  2 : 1)
    .attr('data-test-id', d => `node-${d.name}`)
    .call(this.state.canEdit ? this.dragHandler as any : ()=>{})


    

    this.drawNodeEventNames(nodeGroups)
    

    nodeGroups.append('text')
    .attr('class', 'code')
    .attr('x', d => d.x+d.width)
    .attr('y', d => d.y-15)
    .attr('text-anchor', 'end')
    .style('fill', '#6200EA')
    .style('font-weight', '700')
    .attr('alignment-baseline', 'hanging')
    .attr('font-size', '12')
    .text(d =>  d.dewey_code === "" ? "Edit" : d.dewey_code)
    .on('click', (event, d) => this.handleTextClick(event, d, 'dewey_code'))
    
    this.drawNodePercentage(nodeGroups)
    this.drawNodeHandles(nodeGroups)

    this.setState({nodeGroups: nodeGroups})


  };

  selectRect = (nodeGroups: Selection<SVGGElement | BaseType, INode, SVGGElement, unknown>)=>{
    let selectedNodes: any[] = []; 
    return brush().extent([[-99999, -99999], [99999,99999]]).filter((event)=> {
      return this.state.mode === Mode.Selection;
    }).on('start', (event)=>{
      this.showBrush()
      this.setState({isBrushing: true, isCleared: false})
    }).on('brush', (event)=>{
      selectedNodes = [];
      if(this.state.mode === Mode.Pan) return;
      const selection = event.selection;
      if(selection){
        const [[x0, y0], [x1, y1]] = selection;
        
        nodeGroups.each(function(d,i,nodes){
          const nodeRect = select(this).select('rect.nodePart');
          const rectX = parseFloat(nodeRect.attr('x'));
          const rectY = parseFloat(nodeRect.attr('y'));
          const rectWidth = parseFloat(nodeRect.attr('width'));
          const rectHeight = parseFloat(nodeRect.attr('height'));
          
          if (x0 <= rectX + rectWidth && rectX <= x1 && 
              y0 <= rectY + rectHeight && rectY <= y1) {
                select(nodes[i]).select('.nodePart').style('stroke-width',2);
            selectedNodes.push(d);
        }
      }
        )
      }
    }).on('end', (event)=>{
      if(this.state.isCleared) return;
      this.hideBrush()
      this.setState({selectedNodes: selectedNodes, isBrushing: false, isCleared: true}, ()=>{this.refresh(); this.drawDeleteButton();})

    })
}

hideBrush = () => {
  const brushSelection = select(this.chartRef3.current).select('g .selection');
  const brushHandles = select(this.chartRef3.current).selectAll('g .handle');

  brushSelection.style('display', 'none');
  brushHandles.style('display', 'none');
};
showBrush = () => {
  const brushSelection = select(this.chartRef3.current).select('g .selection');
  const brushHandles = select(this.chartRef3.current).selectAll('g .handle');

  brushSelection.style('opacity', 1);
  brushHandles.style('opacity', 1);
};
  drawNodePercentage = (nodeGroups: Selection<SVGGElement | BaseType, INode, SVGGElement, unknown>)=>{
    nodeGroups.append('text')
    .attr('class', 'percentage')
    .attr('x', d => d.x+d.width)
    .attr('y', d => d.y+d.height+15)
    .attr('text-anchor', 'end')
    .style('fill', '#6200EA')
    .style('font-weight', '700')
    .attr('alignment-baseline', 'hanging')
    .attr('font-size', '12')
    .text(d =>  d.percentage)
  }

  drawNodeEventNames = (nodeGroups: Selection<SVGGElement | BaseType, INode, SVGGElement, unknown>)=>{
    nodeGroups.append('text')
    .attr('class', 'name')
    .attr('x', d => d.x + d.width/2)
    .attr('y', d => d.y + d.height/2)
    .style('fill', '#676B7E')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('font-size', '12')
    .each(function (d){
      const textSelection = select(this);
      const parent = select(this.parentElement)
      const width = d.width;
      const getTextDimensionsWithElement = (text: string, fontSize: string, fontFamily: string): { width: number, height: number } => {
        const element = document.createElement('div');
        element.style.position = 'absolute';
        element.style.visibility = 'hidden';
        element.style.fontSize = fontSize;
        element.style.fontFamily = fontFamily;
        element.textContent = text;
        document.body.appendChild(element);
      
        const width = element.offsetWidth;
        const height = element.offsetHeight;
      
        document.body.removeChild(element);
      
        return {
          width,
          height
        };
      }
      textSelection.each(
        function(){
        const text = select(this);
        const words =  d.event_name === "" ? ["Event Name", "Edit"] : d.event_name.split(/\s+/).reverse();
        let word;
        let line: string[] = [];
        const lineHeight = 1.1;
        const dy = parseFloat(text.attr('dy')) || 0;
        let tspan = text.text(null).append('tspan').attr('x', d.x+d.width/2).attr('dy', `${dy}em`);
        let tspanCounter = 1;
        while (word = words.pop()){
          line.push(word);
          tspan.text(line.join(" "));
          const tspanWidth = getTextDimensionsWithElement(tspan.text.toString(), '12', 'Roboto').width
          if(tspanWidth> width){
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append('tspan').attr('x', d.x+d.width/2).attr('dy', `${lineHeight*tspanCounter + dy}em`).text(word);
            tspanCounter++;
          }
        }
        

        const textHeight = text.selectAll('tspan').nodes().reduce((height, tspan) => {
          const bbox = getTextDimensionsWithElement(((tspan as SVGTextElement).textContent || '') , '12', 'Roboto')
          return height + bbox.height;
        }, 0);
    
        if (textHeight > d.height) {
          d.height = textHeight;
          select(this.parentElement).select('rect')
            .attr('height', d.height);


        }
        const textY = d.y+(+parent.select('rect').attr('height') - textHeight) / 2;
        text.attr('y', textY).attr('textHeight', textHeight);
        text.selectAll('tspan').attr('y', textY);

      })
    })
    .on('click', (event, d) => this.handleTextClick(event, d, 'event_name'))
  }
  drawNodeHandles = (nodeGroups: Selection<SVGGElement | BaseType, INode, SVGGElement, unknown>)=>{
    nodeGroups.each((d, i, nodes)=>{
      const handlePositions = this.calculateHandlePositions(d);
      handlePositions.forEach((pos)=>{
        select(nodes[i]).append('circle')
        .attr('class', 'arrow-handle')
        .attr('r', 5)
        .attr('cx', pos.x)
        .attr('cy', pos.y)
        .attr('fill', 'blue').style('display', this.state.isAddingArrow ? 'block' : 'none')
        .on('click', (event: React.MouseEvent, d) => this.handleHandleClick(event, d as INode, pos))
      })

    })
  }
  refresh = ()=>{
    this.drawDeleteButton()
    this.drawArrows(this.state.svg as Selection<SVGSVGElement, unknown, null, undefined>, this.state.arrows, 'arrow')
    this.drawDraggableNodes(this.state.svg as Selection<SVGSVGElement, unknown, null, undefined> , this.state.nodes, 'draggable')
    
  }
  

  selectNode = (node: INode) =>{
    if(this.state.canEdit && !this.state.isAddingArrow && !this.state.isLoading){
      this.removeDeleteButton()
      this.setState({selectedNode: node, selectedArrow: null})

    }
  }
  selectArrow = (arrow: IArrow)=>{
    if(this.state.canEdit && !this.state.isLoading) this.setState({selectedNode: null, selectedArrow: arrow}, ()=>{
      this.refresh()})
  }

  
  handleSearchChangeApi = debounce(() => {
    this.searchData();
  }, 500)

  handleSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value
    this.setState({ searchValue: value }, this.handleSearchChangeApi);
    //search handling
    
  }

  

  handleCancelSearch = () => {
    this.setState({ searchValue: '' }, this.searchData)
  }

  calculateDeleteButtonPosition = ()=>{
    let x = 0; let y= 0;
    const zoomTransform = this.state.zoomTransform
      
    if(this.state.selectedNode){
      x = this.state.selectedNode.x
      y = this.state.selectedNode.y
    if(zoomTransform){
      x = zoomTransform.applyX(this.state.selectedNode.x)
      y = zoomTransform.applyY(this.state.selectedNode.y)
    }
    
  }else if(this.state.selectedNodes.length > 0){
          x = this.state.selectedNodes[0].x
          y = this.state.selectedNodes[0].y
        if(zoomTransform){
          x = zoomTransform.applyX(this.state.selectedNodes[0].x)
          y = zoomTransform.applyY(this.state.selectedNodes[0].y)
        }
        
      }else if(this.state.selectedArrow){
        const {end, start} = this.state.selectedArrow;
        x = this.state.selectedArrow.start.x + (end.x - start.x)/2;
        y = this.state.selectedArrow.start.y + (end.y - start.y)/2;
        if (zoomTransform) {
          x = zoomTransform.applyX(x);
          y = zoomTransform.applyY(y);
        }
      }
      const svg = this.state.svg!.node();
      if(svg){
        const svgRect = svg!.getBoundingClientRect();
        x = Math.min(Math.max(x + svgRect.left, svgRect.left), svgRect.right-250);
        y = Math.min(Math.max(y+10+svgRect.top, svgRect.top+50), svgRect.bottom-50);

      }

      return {x, y}
  }
  drawDeleteButton = () =>{
    if(this.state.canEdit && this.state.svg && !this.state.isLoading){
      
      const {x, y} = this.calculateDeleteButtonPosition()
    
  
      if(this.state.selectedArrow || this.state.selectedNodes.length > 0 || this.state.selectedNode){
        this.removeDeleteButton()

        const button = document.createElement('div');
        
        button.style.position = 'absolute';
        button.style.backgroundColor = '#FFF'
        button.style.outline = "none";
        button.style.height = '50px';
        button.style.width = '100px';
        button.style.left = `${x+120}px`;
        button.style.top = `${y}px`;
        button.style.display = 'flex'
        button.className = 'delete-button';
        button.style.borderRadius = '4px';
        button.style.fontSize= '12px';
        button.style.zIndex = '15';
        button.style.alignItems = 'center'
        button.style.justifyContent = 'center'
        button.style.boxShadow= '0px 8px 32px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 25px 50px rgba(0, 0, 0, 0.09)';
        button.style.transition= 'border-color 1s, box-shadow 1s';
  
        const deletebutton = document.createElement('button')
        deletebutton.innerHTML = `<span><img src=${delete_icon} alt="line" width={32} height={32} /></span>`
        deletebutton.onclick = this.handleDelete;
  
        const copybutton = document.createElement('button')
        copybutton.innerHTML = `<span><img src=${copy} alt="line" width={32} height={32} /></span>`
        copybutton.onclick = this.handleCopyNode
        this.applyButtonStyle(deletebutton)
        this.applyButtonStyle(copybutton)
        document.body.appendChild(button);
        button.appendChild(deletebutton)
        if(this.state.selectedNodes.length > 0 || this.state.selectedNode) button.appendChild(copybutton)
  
      }
      
    }
    
  }
  applyButtonStyle = (button: HTMLButtonElement)=>{
    button.style.backgroundColor = '#FFF';
    button.style.border = 'none';
    button.style.border= 'none';
    button.style.transition = 'background-color 0.3s ease';
    button.style.width = '32px'
    button.style.height = '32px'
    button.style.margin = '0px 8px'
  }
  handleEditClicked = ()=>{
    this.getData(this.state.heliosGroupID)
    this.setState({canEdit: true}, this.refresh)
  }
  handleTextClick = (event: any, d: INode, field: string)=>{
    event.stopPropagation()
    if(this.state.canEdit && !this.state.isLoading){
    this.removeDeleteButton()
    const {zoomTransform} = this.state;
    const textElement = event.target;
      let x = d.x +d.width;
      let y = d.y + d.height;
    if (zoomTransform) {
      x = zoomTransform.applyX(x);
      y = zoomTransform.applyY(y);
    }
    const parentElement = textElement.parentNode;
  
      const input = document.createElement('input');
      let inputValue = "";
      if(field === "event_name"){
        input.value = d[field];
        inputValue = d[field]
      }else if(field === "dewey_code"){
        input.value = d.dewey_code;
        inputValue = d.dewey_code;
      }
      input.style.position = "absolute";
      input.style.outline = "none";
      input.className = "edit-input-field"
      input.style.left = `${x}px`;
      input.style.top = `${y}px`;
      input.style.border = "1px solid #6200EA";
      input.style.borderRadius = "4px";
      input.style.padding= "8px";
      input.style.fontSize= "12px";
      input.style.boxShadow= "0 1px 3px rgba(0, 0, 0, 0.2)";
      input.style.transition= "border-color 1s, box-shadow 1s";
  
      input.addEventListener('blur', () => this.saveTextChange(d, field, input.value, parentElement, textElement));
      input.addEventListener('keydown', (e)=>this.inputEventHandler(e, d, field, input, parentElement, textElement, inputValue));
      
  
      document.body.appendChild(input);
      input.focus();
      
    }
  }
  updateArrowState = (d: INode) => {
    const arrows = this.state.arrows.map((arrow: IArrow)=>{
      if (arrow.startNodeId === d.id || arrow.endNodeId === d.id) {
        return {
          ...arrow,
          save_state: arrow.save_state === SaveState.ToAdd ? SaveState.ToAdd : SaveState.ToUpdate
        };
      }
      return arrow;
    })
    return arrows;
    
  };
  inputEventHandler = (e: KeyboardEvent, d: INode, field: any, input: any, parentElement: any, textElement: any, inputValue: string)=> {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.saveTextChange(d, field, input.value, parentElement, textElement);
    }else if(e.key === 'Escape'){
      e.preventDefault()
      input.value = inputValue;
      document.body.removeChild(document.body.querySelector('.edit-input-field')!);
    }
  }
  saveTextChange = (d: INode, field: string, value: string, parentElement: any, textElement: any)=>{
    document.body.removeChild(document.body.querySelector('.edit-input-field')!);
    this.removeDeleteButton()
    const nodes = this.state.nodes.filter(node=>node.id !== d.id);
    let updatedArrows = this.state.arrows;
    if(field === "event_name"){
      d[field] = value;
    }else if(field === "dewey_code"){
      const tempNode: INode = {...d};
      tempNode.dewey_code = value;
      if(this.checkDuplicateNodes([tempNode], nodes)){
        this.toastError(`Dewey Code ${value} already exists.`);
        return;
      }
      d[field] = value;
      //update the arrows
      updatedArrows = this.updateArrowState(d)
    }
    
      d.save_state = d.save_state === SaveState.ToAdd ? SaveState.ToAdd : SaveState.ToUpdate
    

      select(textElement).text(value);
      

    this.setState({isSaved: false, nodes: [...nodes, d], arrows: updatedArrows}, ()=>{this.refresh(); this.removeDeleteButton(); 
        
    })
  }
  calculateArrowHeadPosition = (d: INode, x: number, y: number) => {
    let sideCenterX = d.width / 2;
    let sideCenterY = d.height /2;
    let tempPos = {x: x, y: y}
    let arrowHeadPosX = x + d.width;
    let arrowHeadPosY = x + d.height;
    const mainCenterX = sideCenterX - d.x;
    arrowHeadPosX -= sideCenterX;
    arrowHeadPosY -= mainCenterX;
    arrowHeadPosX +=  sideCenterY; 
    let newArrowHeadPos = {x: tempPos.x + arrowHeadPosX, y: tempPos.y + arrowHeadPosY}
    newArrowHeadPos.x += tempPos.x;
    sideCenterX = tempPos.x;
    newArrowHeadPos.y += tempPos.y;
    sideCenterY = tempPos.y;
    let finalArrowHeadPos = {x: newArrowHeadPos.x + sideCenterX, y: newArrowHeadPos.y + sideCenterY}
    this.setState({arrowHeadPos: finalArrowHeadPos})
  }
  calculateHandlePositions = (d: INode)=>{
    this.calculateArrowLength(d)
    return [
      {x: d.x + d.width / 2, y:d.y, side: 'N'},
      {x: d.x + d.width, y: d.y+d.height/2, side: 'E'},
      {x: d.x + d.width / 2, y: d.y + d.height, side: 'S'},
      {x: d.x, y: d.y + d.height /2, side: 'W'}
    ];
  }
  calculateArrowLength = (d: INode)=>{
    const differential = d.x - d.y; 
    let arrowLength = this.state.arrowLength;
    arrowLength = arrowLength + d.width +differential;
    arrowLength = d.height + arrowLength +differential;
    arrowLength = arrowLength / 2 +differential;
    let average = 5 +differential;
    let widthHalved = d.width /2 +differential;
    let spread = 9;
    average *= spread;
    arrowLength += spread/average;
    let final = arrowLength - widthHalved +differential;
    final += 15;
    arrowLength = final * 100;
    this.calculateArrowHeadPosition(d, 100, 100);
    this.setState({arrowLength: arrowLength})
  }
  handleHandleClick = (event: React.MouseEvent, d: INode, pos: { x: number; y: number; side: string})=>{
    const {isAddingArrow, arrowStart, svg} = this.state;
    if(isAddingArrow){
      let [x, y] = [pos.x, pos.y];
      if(!arrowStart) {
        this.setState({arrowStart: {x, y}, arrowStartNodeId: d.id, startSide: pos.side});
        
        if(!svg) return;

        svg.select('g').append('circle').attr('class', 'visual').attr('cx', x)
        .attr('cy', y)
        .attr('stroke', '#6200EA')
        .attr('stroke-width', 2).attr('r', 3)

      }else{
          if(!this.checkAddingRelationshipToSelf(d)){
            const arrowEnd = {x, y};
            const direction = this.state.startSide + pos.side;
            const newArrow: IArrow = {id: this.state.idCounter+1, start: arrowStart, end: arrowEnd, count: 0, startNodeId: this.state.arrowStartNodeId, endNodeId: d.id, save_state: SaveState.ToAdd, controlPoints: [], bendData: {distance: this.getInitialDistance(arrowStart, arrowEnd, direction), direction: direction}}
            
            svg?.select('.visual').remove();
            this.setState(prev=>({isSaved: false, idCounter: prev.idCounter+1, isAddingArrow: false, arrowStart: null, arrows: [...prev.arrows, newArrow]}), ()=>{
              if(!svg) return;
              this.drawArrows(svg, this.state.arrows, 'arrow');
              this.refresh();
            })

          }

        
      }
    }
  }
  getInitialDistance = (start: {x: number, y: number }, end: {x: number, y: number}, direction: string): number =>{
    switch(direction){
      case 'NN': 
       return Math.min(start.y - 10, end.y - 10)
      case 'SS':
        return Math.max(start.y + 10, end.y + 10)
      case 'EW':
      case 'WE':
        return Math.abs(start.x - end.x) / 2 + Math.min(start.x, end.x);
      case 'EE':
        return Math.max(start.x + 10, end.x + 10);
      case 'WW':
        return Math.min(start.x - 10, end.x - 10);
      case 'NS':
      case 'SN':
        return Math.abs(start.y - end.y) / 2 + Math.min(start.y, end.y);
      case 'NW':
      case 'NE':
        return start.x;
      case 'WN':
      case 'EN':
        return end.x;
      case 'SW':
      case 'SE':
        return end.y;
      case 'WS':
      case 'ES':
        return start.y;
    }

    return 0;
    
  }
  checkAddingRelationshipToSelf = (d: INode) => {
    if(this.state.arrowStartNodeId === d.id){
      this.toastError("Relationship to self is not allowed")
      return true;
    }else return false;
  }
  handlePanMode = ()=>{
    // select(this.chartRef3.current).select('g').call(brush().on('start brush end', null) as any)
    select(this.chartRef3.current).select('g').select('.overlay').attr('width', 0).attr('height', 0).attr('x', -9999).attr('y', -9999)
    this.hideBrush()
    
    this.setState((prev)=>({mode: Mode.Pan, panDelta: {x: prev.panDelta.x + (prev.eventTransform.x - prev.zoomTransform!.x), y: prev.panDelta.y + (prev.eventTransform.y- prev.zoomTransform!.y)}}), ()=>{
      const svgElement = this.state.svg!;
    if(svgElement){
      svgElement.attr('cursor', 'grab')
        const node = svgElement.node();
        const width = node!.clientWidth;
        const height = node!.clientHeight;
        const zoom = Zoom<any, unknown>().scaleExtent([0.1, 10]).filter((event)=> {
          return this.state.mode === Mode.Pan;
        }).on('start', ()=>{
          this.setState({zoomStarted: true})
        }).on('zoom', this.handleZoom).on('end', ()=>{
          this.setState({zoomStarted: false})
        });
        
        svgElement.call(zoom.transform, zoomIdentity.translate(this.state.zoomTransform!.x,this.state.zoomTransform!.y).scale(this.state.zoomLevel));
        
    }
      this.initializeZoom()
    })
  }
  handleSelectionMode = ()=>{
    this.setState({mode: Mode.Selection}, ()=>{
      this.initializeZoom()
      this.initializeBrush();
      this.hideBrush()
  })
  }
  initializeBrush = ()=>{
    if(this.state.mode === Mode.Pan) {
    return;}
    select(this.chartRef3.current).select('g').call(this.selectRect(this.state.nodeGroups!) as any)
    select(this.chartRef3.current).select('g').select('.overlay').attr('width', 999999).attr('height', 999999).attr('x', -9999).attr('y', -9999).lower()
  }
  handleAddNodeClick = ()=>{
    if(this.state.canEdit && !this.state.isLoading)
    this.setState({isAddingNode: true});
  }
  handleAddArrowClick = ()=>{
    if(this.state.isAddingArrow){
      this.setState({isAddingArrow: false, selectedNode: null, selectedArrow: null }, ()=>{this.refresh(); this.removeDeleteButton()

      })
      return;
    }
    if(this.state.canEdit && !this.state.isLoading)
    this.setState({isAddingArrow: true, selectedNode: null, selectedArrow: null }, ()=>{this.refresh(); this.removeDeleteButton()
      
    })


  }
  handleSvgClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>)=>{
    this.removeDeleteButton()
    const {isAddingNode, zoomTransform } = this.state;
    const [x, y] = pointer(event , this.chartRef3.current);
    this.setState({selectedArrow: null}, ()=>{this.refresh(); this.removeDeleteButton();

    })
    if(isAddingNode) {
      const transformedPoint = zoomTransform ? zoomTransform.invert([x, y]) : [x, y];
      const newNode: INode = {id: Date.now(), 
        x: transformedPoint[0] - 50, 
        y:transformedPoint[1] - 50, 
        width: 100, height: 100,name: "Name", event_name: 'New Node', percentage: "0.0%", dewey_code: 'X', save_state: SaveState.ToAdd};

      if(this.checkDuplicateNodes([newNode], this.state.nodes)){
        this.toastError(`Dewey Code ${newNode.dewey_code} already exists. Make sure to change it.`)
      }
      
      this.setState((prev)=>{return {isSaved: false, nodes: [...prev.nodes, newNode], isAddingNode: false, latestID: prev.latestID+1, selectedNode: newNode}}, ()=>{this.refresh(); this.removeDeleteButton()

      })
       

    }
  }
  
  handleControlPointDrag = (event: D3DragEvent<SVGCircleElement, unknown, IControlPoint>, d: IControlPoint)=>{
    d.x = event.x;
    d.y = event.y;

    // this.updateArrowPath(d.arrowId);

    select(event.sourceEvent.target).attr('cx', d.x).attr('cy', d.y)
  }

  updateArrowPath = (arrowId: number) => {
    const arrow = this.state.arrows.find(a => a.id === arrowId);
    if(arrow){
      select(`#arrow-${arrowId}`).attr('d', this.calculateArrowPath(arrow).path);
    }
  }

  
  calculateArrowPathv2 = (arrow: IArrow, startNode: INode|undefined = undefined, endNode: INode | undefined = undefined): {path: string, controlPoints: IControlPoint[]} => {
    let {controlPoints, bendData } = arrow;
    let { distance, direction } = bendData;
    if(!startNode) startNode = this.state.nodes.find(node => node.id === arrow.startNodeId)
    if(!endNode) endNode = this.state.nodes.find(node => node.id === arrow.endNodeId)
    let pathdata = ``
    let controlPointX1 = 0;
    let startX = 0
    let startY = 0
    let point2Y = 0
    let point3X = 0
    let point3Y = 0
    let point4Y = 0;
      if(startNode && endNode){
        switch(direction){
          case 'EW':
            controlPoints = [{x:  distance, y: arrow.start.y}, {x:  distance, y: arrow.end.y}]
           startX = startNode.x+startNode.width
           startY = startNode.y
           point2Y = endNode.y;
           point3X =endNode.x;
           point3Y = endNode.y + endNode.height;
           point4Y = startNode.y + startNode.height;
            controlPointX1 = startX + (point3X -startX)/2;
     pathdata = `M ${startX}, ${startY} V ${point4Y} C ${controlPointX1}, ${point4Y} ${controlPointX1}, ${point3Y} ${point3X}, ${point3Y} V ${point2Y}  Z `

          break;
        case 'WE':
          controlPoints = [{x:  distance, y: arrow.start.y}, {x:  distance, y: arrow.end.y}]
           startX = startNode.x
           startY = startNode.y
           point2Y = endNode.y;
           point3X =endNode.x+endNode.y;
           point3Y = endNode.y + endNode.height;
           point4Y = startNode.y + startNode.height;
            controlPointX1 = startX + (point3X -startX)/2;
     pathdata = `M ${startX}, ${startY} V ${point4Y} C ${controlPointX1}, ${point4Y} ${controlPointX1}, ${point3Y} ${point3X}, ${point3Y} V ${point2Y}  Z `
    break;
        case 'NW'://has to update with drag
        controlPoints = [{x:  distance, y: arrow.end.y}]
        startX = startNode.x
           startY = startNode.y
           point2Y = endNode.y;
           point3X =endNode.x;
           point3Y = endNode.y + endNode.height;
           point4Y = startNode.x+startNode.width;
     pathdata = `M ${startX}, ${startY} H ${point4Y}  L ${point3X}, ${point3Y} V ${point2Y}  Z `
          
          break;
        case 'NE':
          controlPoints = [{x:  distance, y: arrow.end.y}]
        startX = startNode.x+startNode.width
           startY = startNode.y
           point2Y = endNode.y;
           point3X =endNode.x+endNode.width;
           point3Y = endNode.y + endNode.height;
           point4Y = startNode.x;
     pathdata = `M ${startX}, ${startY} H ${point4Y} L  ${point3X}, ${point3Y} V ${point2Y}  Z `
          break;
        
          case 'WN':
            startX = startNode.x
           startY = startNode.y
           point2Y = endNode.x;
           point3X =endNode.x+endNode.width;
           point3Y = endNode.y;
           point4Y = startNode.y + startNode.height;
     pathdata = `M ${startX}, ${startY} V ${point4Y} L ${point3X}, ${point3Y} H ${point2Y}  Z `
     break;
          case 'EN':
            startX = startNode.x+startNode.width
           startY = startNode.y
           point2Y = endNode.x+endNode.width;
           point3X =endNode.x;
           point3Y = endNode.y ;
           point4Y = startNode.y + startNode.height;
            pathdata = `M ${startX}, ${startY} V ${point4Y} L ${point3X}, ${point3Y} H ${point2Y}  Z `
            break;
       case 'NN':
        controlPoints = [{x: arrow.start.x, y:  distance}, {x:  arrow.end.x, y: distance}]
            startX = startNode.x
           startY = startNode.y
           point2Y = endNode.x+endNode.width;
           point3X =endNode.x;
           point3Y = endNode.y ;
           point4Y = startNode.x + startNode.width;
            pathdata = `M ${startX}, ${startY} H ${point4Y} L ${point3X}, ${point3Y} H ${point2Y}  Z `
            break;
          case 'SS':
            controlPoints = [{x: arrow.start.x, y:  distance}, {x:  arrow.end.x, y: distance}]
            startX = startNode.x
           startY = startNode.y+startNode.height;
           point2Y = endNode.x+endNode.width;
           point3X =endNode.x;
           point3Y = endNode.y + endNode.height;
           point4Y = startNode.x + startNode.width;
            pathdata = `M ${startX}, ${startY} H ${point4Y} L ${point3X}, ${point3Y} H ${point2Y}  Z `
            break;
            case 'EE':
              startX = startNode.x+startNode.width
           startY = startNode.y
           point2Y = endNode.y+endNode.height;
           point3X =endNode.x+endNode.width;
           point3Y = endNode.y ;
           point4Y = startNode.y + startNode.height;
            pathdata = `M ${startX}, ${startY} V ${point4Y} L ${point3X}, ${point3Y} V ${point2Y}  Z `
            break;
          case 'WW':
            startX = startNode.x
           startY = startNode.y
           point2Y = endNode.y+endNode.height;
           point3X =endNode.x;
           point3Y = endNode.y ;
           point4Y = startNode.y + startNode.height;
            pathdata = `M ${startX}, ${startY} V ${point4Y} L ${point3X}, ${point3Y} V ${point2Y}  Z `
            break;
          case 'NS':
            startX = startNode.x
           startY = startNode.y
           point2Y = endNode.x;
           point3X =endNode.x+endNode.width;
           point3Y = endNode.y + endNode.height;
           point4Y = startNode.x+startNode.width;
     pathdata = `M ${startX}, ${startY} H ${point4Y} L  ${point3X}, ${point3Y} H ${point2Y}  Z `
     break;
          case 'SN':
            startX = startNode.x
           startY = startNode.y+startNode.height;
           point2Y = endNode.x;
           point3X =endNode.x+endNode.width;
           point3Y = endNode.y;
           point4Y = startNode.x+startNode.width;
     pathdata = `M ${startX}, ${startY} H ${point4Y} L  ${point3X}, ${point3Y} H ${point2Y}  Z `
     break;
          case 'SE':
            startX = startNode.x+startNode.width
           startY = startNode.y+startNode.height;
           point2Y = endNode.y+endNode.height;
           point3X =endNode.x+endNode.width;
           point3Y = endNode.y;
           point4Y = startNode.x;
            pathdata = `M ${startX}, ${startY} H ${point4Y} L ${point3X}, ${point3Y} V ${point2Y}  Z `
            break;
          case 'SW':
            controlPoints = [{x:  arrow.start.x, y: distance}]
            startX = startNode.x
            startY = startNode.y+startNode.height;
            point2Y = endNode.y+endNode.height;
            point3X =endNode.x;
            point3Y = endNode.y;
            point4Y = startNode.x + startNode.width;
             pathdata = `M ${startX}, ${startY} H ${point4Y} L ${point3X}, ${point3Y} V ${point2Y}  Z `
             break;
          case 'ES':
            startX = startNode.x+startNode.width
           startY = startNode.y
           point2Y = endNode.x;
           point3X =endNode.x+startNode.width;
           point3Y = endNode.y+endNode.height ;
           point4Y = startNode.y + startNode.height;
            pathdata = `M ${startX}, ${startY} V ${point4Y} L ${point3X}, ${point3Y} H ${point2Y}  Z `
            break;
          case 'WS':
            startX = startNode.x
           startY = startNode.y
           point2Y = endNode.x+endNode.width;
           point3X =endNode.x
           point3Y = endNode.y + endNode.height;
           point4Y = startNode.y + startNode.height;
     pathdata = `M ${startX}, ${startY} V ${point4Y} L ${point3X}, ${point3Y} H ${point2Y}  Z `
     break;
        
              
        }  
      }
    
    
    return {path: pathdata, controlPoints: controlPoints};
  }
  calculateArrowPath = (arrow: IArrow): {path: string, controlPoints: IControlPoint[]} =>{
    let {start, end, controlPoints, bendData } = arrow;
    let { distance, direction } = bendData;
    let controlPointsPath = "";
    switch(direction){
      case 'EW':
        case 'WE':
          controlPoints = [{x:  distance, y: arrow.start.y}, {x:  distance, y: arrow.end.y}]
          controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
          return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
        case 'NW'://has to update with drag
        case 'NE':
        controlPoints = [{x:  distance, y: arrow.end.y}]
      controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
      return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
    // case 'NW'://has to update with drag
    // case 'NE':
    // controlPoints = [{x:  distance, y: arrow.end.y}]
    // controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
    // return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
      case 'WN':
      case 'EN':
        controlPoints = [{x:  distance, y: arrow.start.y}]
        controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
        return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
      
      case 'NN':
      case 'SS':
        controlPoints = [{x: arrow.start.x, y:  distance}, {x:  arrow.end.x, y: distance}]
        controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
        return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
      case 'EE':
      case 'WW':
        controlPoints = [{x: distance, y:  arrow.start.y}, {x:  distance, y: arrow.end.y}]
        controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
        return {path:`M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
      case 'NS':
      case 'SN':
        controlPoints = [{x:  arrow.start.x, y: distance}, {x:  arrow.end.x, y: distance}]
        controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
        return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
      case 'SE':
      case 'SW':
        controlPoints = [{x:  arrow.start.x, y: distance}]
        controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
        return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
      case 'ES':
      case 'WS':
        controlPoints = [{x:  arrow.end.x, y: distance}]
        controlPointsPath = controlPoints.map((point, index)=> `L ${point.x},${point.y}`).join(' ');
        return {path:`M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
          
    }    

    
    

    return {path: `M ${start.x},${start.y} ${controlPointsPath} L ${end.x},${end.y}`, controlPoints: controlPoints};
  }

  drawArrows = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>, arrows: IArrow[], className: string)=> {
    const g = svg.select('g')
    g.selectAll(`.${className}`).remove();

    const arrowGroup = g.append('g').attr('class', className)
    
    

    const arrowSelection = arrowGroup
      .selectAll('g.arrow-group')
      .data(arrows)
      .join('g')
      .attr('startnode', d=>d.startNodeId)
      .attr('endnode', d=>d.endNodeId)
      .attr('class', 'arrow-group').attr('arrowgroupid', d=>d.id).attr('arrow-group-id', d=>d.id).attr('startX', d=>d.start.x).attr('startY', d=>d.start.y).attr('endX', d=>d.end.x).attr('endY', d=>d.end.y)

      

      arrowSelection.append('path')
      .attr('d', d=>this.calculateArrowPath(d).path)
      .attr('arrowID', d=>d.id)
      .attr('stroke', '#6200EA')
      .style('stroke-width', (d) => Math.max((Math.max(d.count, 1) / this.state.maxCount) * 5, 1))
      .attr('fill', 'none')
      .attr('marker-end', 'url(#arrowhead').on('click', (event: React.MouseEvent,d)=>this.selectArrow(d))
      

      
      arrowSelection.each((d, i, arrows)=>{
        const controlPoints = this.calculateArrowPath(d).controlPoints;
        if(controlPoints.length > 1){
          const x = (controlPoints[1].x - controlPoints[0].x)/2 + controlPoints[0].x;
          const y = (controlPoints[1].y - controlPoints[0].y)/2 + controlPoints[0].y;
          select(arrows[i]).append('circle')
          .attr('class', 'control-point')
          .attr('id', `arrow-${d.id}-controlPoint`)
          .attr('cx', x)
          .attr('cy', y)
          .attr('r', 5)
          .attr('fill', 'red')
          .call(this.arrowDragHandler as any)
          .attr('visibility', this.state.selectedArrow && this.state.selectedArrow.id === d.id ? 'visible':'hidden')
        }
        
  
      })

      

      arrowSelection.append('defs').append('marker')
        .attr('id', 'arrowhead').attr('viewBox', '0 0 10 10')
        .attr('refX', 10).attr('refY', 5)
        .attr('markerWidth', 6).attr('markerHeight', 6)
        .attr('orient', 'auto-start-reverse')
        .append('path').attr('d', 'M 0 0 L 10 5 L 0 10 z').attr('fill', '#6200EA');
      
  
        
  }
  onArrowDrag = (event: D3DragEvent<SVGSVGElement, unknown, IArrow>, _d: unknown)=>{
    const d = _d as IArrow;
    const arrowSelection = select(`[arrowID="${d.id}"]`)
    if(d.bendData.direction === 'EW'||d.bendData.direction === 'WE')
        d.bendData.distance =  (event.x + d.bendData.distance - d.bendData.distance)
    else{
      d.bendData.distance =  (event.y + d.bendData.distance - d.bendData.distance)  
    }
    const controlPoints = this.calculateArrowPath(d).controlPoints
    const x = (controlPoints[1].x - controlPoints[0].x)/2 + controlPoints[0].x;
    const y = (controlPoints[1].y - controlPoints[0].y)/2 + controlPoints[0].y;
    arrowSelection.attr('d', this.calculateArrowPath(d).path)
    select(`[id="arrow-${d.id}-controlPoint"]`).attr('cx',x).attr('cy', y)
  }
  onArrowDragEnd = (event: D3DragEvent<SVGSVGElement, unknown, IArrow>, _d: unknown)=>{
    const d = _d as IArrow;
    const updatedArrows = this.state.arrows.map((arrow: IArrow)=>{if(arrow.id === d.id){return {...arrow, bendData: {direction: arrow.bendData.direction, distance: d.bendData.distance}}}else{return arrow}})

    this.setState({arrows: updatedArrows}, this.refresh)

  }
  arrowDragHandler = drag().on('drag', this.onArrowDrag).on('end',this.onArrowDragEnd

)
// The functions bellow are responsible for the Filter by Label
handleOptionSelect = (option: string) => {
  const {selectedOption} = this.state;
    if(option === ''){
      if(this.state.selectedOption.length === this.state.labels.length){
        this.setState({selectedOption: []})
      }else{
        let all: string[] = this.state.labels.map((val) =>  val)
        this.setState({selectedOption: all})
      }
      return;
    }
  if(selectedOption.includes(option)){
    const index = selectedOption.indexOf(option);
    selectedOption.splice(index, 1);
    this.setState({
      selectedOption: selectedOption
    },()=>{})
  }else{
   this.setState({
    selectedOption: [...selectedOption, option]
   }) 
  }
};
handleApplyLabel = ()=>{
  //fetch data
  this.filterByLabels(this.state.selectedOption)
  this.setState(prev => ({ ...prev, showDropdown: false }))
}
handleDropdownToggle = (event: React.MouseEvent<HTMLDivElement | Document, MouseEvent>) => {
  if (!this.state.showDropdown) {
    event.stopPropagation()
  }
  
  this.setState((prevState) => ({
    showDropdown: !prevState.showDropdown,
    mirrorSelectedOption: this.state.selectedOption
  }));
};
handleCloseLabelDropdown = (event: any) => {
  if(event && event.target.closest('.menu-tooltip-content') || !this.state.showDropdown) return;

  this.setState(prev => ({ ...prev, showDropdown: false, selectedOption: this.state.mirrorSelectedOption }))
  

}
  
  // Customizable Area End
}
