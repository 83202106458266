Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.analyticsUniqueStages = 'bx_block_events_raw/events_raws/unique_stages';
exports.analyticsUniqueBoxes = 'bx_block_events_raw/events_raws/unique_boxes';
exports.analyticsUniqueFlows = 'bx_block_events_raw/events_raws/unique_flows';
exports.analyticsBoxesFilter = '/bx_block_events_raw/events_raws/box_list';
exports.analyticsFlowsFilter = '/bx_block_events_raw/events_raws/flow_list';
exports.analyticsEventNames = '/bx_block_events_raw/dewey_event_reference';
exports.getsChartDataEndPoint = "/bx_block_events_raw/events_raws";
exports.postHeliosData = 'bx_block_content_management/add_helios_data_card' 
exports.updateHeliosData = 'bx_block_content_management/update_helios_event' 
exports.updateHeliosDirection = 'bx_block_content_management/update_helios_direction' 
exports.deleteHeliosData = 'bx_block_content_management/delete_helios_event' 
exports.deleteHeliosDirection = 'bx_block_content_management/delete_helios_direction' 
exports.postDataDirection = '/bx_block_content_management/add_card_directions'
exports.getGroupEvents = '/bx_block_content_management/group_events'
exports.getGroups = '/bx_block_content_management/helios_groups'
exports.postGroup = '/bx_block_content_management/add_helios_group'
exports.postCreateDirection = '/bx_block_content_management/save_connections'
exports.search = 'bx_block_content_management/search'
exports.getWhiteBoardTransform = 'bx_block_analytics/flow_metadata'
exports.updateWhiteBoardTransform = 'bx_block_analytics/flow_metadata'
exports.postNewArrowData = 'bx_block_content_management/mapping_data_update'
exports.filterByLables = 'bx_block_events_raw/labels/filter_by_labels'
exports.getLabels = 'bx_block_events_raw/labels'
exports.getArrowCount = 'bx_block_events_raw/events_raws/unique_bc_ids_between_dewey_codes'
exports.getApiMethod = 'GET';
exports.postAPIMethod = 'POST';
exports.putAPIMethod = 'PUT';
exports.deleteAPIMethod = 'DELETE';
// Customizable Area End