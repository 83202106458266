import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import withStyles from '@mui/styles/withStyles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import PanToolIcon from '@mui/icons-material/PanTool';
import NearMeIcon from '@mui/icons-material/NearMe';

import WhiteboardController, {
  Mode,
  Props,
} from "./WhiteboardController.web";
import {
  Button,
  AppBar, Snackbar, Alert, InputBase, 
  FormControlLabel,
  FormGroup,
  Checkbox,
  Badge,
  Tooltip,
  IconButton,
  ClickAwayListener,
  Divider,
  styled
} from '@mui/material';
import {
  rectangle, arrow, zoom,
  filter_Icon
} from "../src/assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { Prompt } from 'react-router-dom';
// Customizable Area End

export default class Whiteboard extends WhiteboardController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.initializeUser()
    document.addEventListener('keydown', this.handleKeyDown);
    // document.addEventListener('wheel', this.handleScroll, {passive: false})

    this.drawDragChart(this.chartRef3.current as SVGSVGElement)

  }
// componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
//   if(prevState.mode !== this.state.mode){
//     this.initializeZoom();
//   }
// }
  async componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    this.removeDeleteButton()
    this.removeEditInput()
  }
  optionSelect = () => {
    return (
      <span style={{ fontSize: '14px', fontFamily: 'Inter', lineHeight: '22px', fontWeight: '600', color: '#475568' }}>{'Labels  '}
      {/* <span style={{position: 'absolute', top: '-5px', right: '-5px', width:'8px', height: '8px', backgroundColor: 'red', borderRadius: '50%'}}/> */}
      </span>
    )
  }
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={webStyle.container} >
          <div>
          <AppBar position="static" style={webStyle.whiteboardHeader}>
            <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '0px 30px',
                    gap: 5,
                    maxWidth: '100%',
                    color: 'black'
                  }}>
              
              <Button data-test-id="back-to-analytics-button" onClick={this.handleNavigateWhiteboard} disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                      Back
              </Button>
              <div style={{
                paddingLeft: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: '12px',
                border: '1px solid #CBD5E1',
                backgroundColor: '#fff',
                maxWidth: '50%',
                flexGrow: 1,
                width: '50%',
                marginLeft: 40,
              }}> 
                <div style={{
                  padding: '4px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <SearchIcon style={{ color: '#94A3B8' }} />
                </div>
                <InputBase
                  value={this.state.searchValue}
                  placeholder="Search by keyword"
                  onChange={this.handleSearchChange}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ height: '44px', width: 'calc(100% - 12px)', fontFamily: 'Lato', fontSize: '16px', }}
                  data-test-id='search'
                />
                {
                  this.state.searchValue.length > 0 ?
                    (<IconButton
                    style={{
                        padding: '4px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    onClick={this.handleCancelSearch}
                    data-test-id='cancel-search'
                    size="large">
                      <CloseIcon fontSize='inherit' style={{ color: '#94A3B8' }} />
                    </IconButton>) : (<></>)
                }
              </div>

              
              <span style={{display: "flex", alignItems: 'center', justifyContent: 'center', gap: 20}}>
              <ClickAwayListener onClickAway={this.handleCloseLabelDropdown}>
                  <MenuTooltip
                    open={this.state.showDropdown}
                    title={
                      <React.Fragment>
                        <div style={webStyle.tooltipBody} className="menu-tooltip-content">
                          <FormGroup style={{marginBottom: '16px'}}>
                         <FormControlLabel 
                            labelPlacement="start" 
                            data-test-id='label-all'
                            style={{ alignItems: 'center', justifyContent: 'space-between' }} 
                            value="" 
                            control={<Checkbox checked={this.state.selectedOption.length === this.state.labels.length}  onChange={(e)=>{this.handleOptionSelect(e.target.value)}} style={{ color: '#6200EA' }} />} 
                            label={<span style={{ fontFamily: 'Rubik' , overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', width: '200px' }}>All</span>} 
                          />
                          <Divider variant="middle"/>
                          {this.state.labels.map((val, idx) => (
                              <>
                              <FormControlLabel
                                data-test-id={`label-${val}`}
                                labelPlacement='start'
                                style={{ alignItems: 'center', justifyContent: 'space-between' }} 
                                value={val}
                                control={<Checkbox checked={this.state.selectedOption.includes(val)} onChange={(e)=>{this.handleOptionSelect(e.target.value)}} style={{ color: '#6200EA' }} />}
                                label={<span style={{ fontFamily: 'Rubik', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', width: '200px' }}>{val}</span>}
                                key={`${idx}-${val}`}
                                />
                            <Divider variant="middle"/>
                                </>

                            ))}
                          
                          </FormGroup>

                          <div style={{display: 'flex', borderTop: '1px solid rgb(203, 213, 225)', justifyContent: 'right', alignItems: 'center', width: '100%', position: 'absolute',bottom: '0px', right: '0px', backgroundColor: 'white'}}>
                          
                            
                            <Button
                            data-test-id={'label-apply'}
                            color='primary'
                            style={{color: '#6200EA',
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '24px',
                              textTransform: 'none', zIndex: 5}}
                            onClick={()=>{
                                this.handleApplyLabel()
                            }}
                          >Apply</Button>
                          </div>
                          
                        </div>
                      </React.Fragment>
                    }
                    placement="bottom-end"
                    
                  >
                    <div data-test-id='label-filter' style={{ display: 'flex', flexDirection: 'row', alignItems: 'right', cursor: 'pointer', marginLeft: 40}} onClick={this.handleDropdownToggle}>
                    <CustomBadge variant="dot" invisible={this.state.selectedOption.length <= 0 || this.state.selectedOption.length >= this.state.labels.length}  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}>
                      <img src={filter_Icon} alt="Priority Icon" style={{ width: '24px', height: '24px', paddingRight: '10px' }} />
                      {this.optionSelect()}
                      </CustomBadge>
                    </div>
                  </MenuTooltip>
                </ClickAwayListener>
                {this.state.isSuperUser && !this.state.canEdit && !this.state.isLoading && (<Button data-test-id="edit-mode-button" disabled={this.state.isSaving || this.state.isLoading} onClick={this.handleEditClicked} disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                  {this.state.isSaving ? 'Saving...' : 'Edit' }
              </Button>)}
              {this.state.isSuperUser && this.state.isLoading && (<Button data-test-id="loading-mode-button" disabled={this.state.isSaving || this.state.isLoading} disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                      Loading...
              </Button>)}
              {this.state.isSuperUser && this.state.canEdit && !this.state.isLoading && (<Button data-test-id="save-button" onClick={this.handleSave} disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                      Save
              </Button>)}
              </span>
            </div>

            
            
          </AppBar>
        </div>
            <div style={webStyle.mainWrapper}>
              <div style={webStyle.secondWrapper}>
                <div style={webStyle.flexdisplay} >
                  {this.state.canEdit && !this.state.isLoading &&(
                  <div style={webStyle.toolbar}>
                    <button data-test-id="add-button" id="addNodeButton" disabled={this.state.isSaving} onClick={this.handleAddNodeClick} style={webStyle.tool}><img src={rectangle} width={32} height={32}  alt="rectangle" /></button>
                    <button data-test-id="add-arrow-button" id="addArrowButton" disabled={this.state.isSaving} onClick={this.handleAddArrowClick} style={webStyle.tool}><img src={arrow} alt="arrow" width={32} height={32} /></button>
                    <button data-test-id="pan-button" id="panButton"  disabled={this.state.isSaving} onClick={this.handlePanMode} style={webStyle.tool}><PanToolIcon sx={{color: this.state.mode === Mode.Pan ? '#6200EA' : 'black' }}/></button>
                    <button data-test-id="selection-button" id="selectionButton" disabled={this.state.isSaving} onClick={this.handleSelectionMode} style={webStyle.tool}><NearMeIcon sx={{color: this.state.mode === Mode.Selection ? '#6200EA' : 'black' }}/></button>
                  </div>)}
                  <svg ref={this.chartRef3} data-test-id="whiteboard-svg"  onClick={this.handleSvgClick} style={{backgroundColor: "white", cursor: this.state.isAddingNode || this.state.isAddingArrow ? 'crosshair' : 'default'}} width={'100%'} height={"100%"}>
                    <g />
                  </svg>{this.state.isSuperUser && this.state.mode !== Mode.Selection && (
                  <div style={webStyle.zoombar}>
                    <button style={webStyle.zoomButton} disabled={this.state.isSaving} onClick={this.zoomOut}>-</button>
                    <span style={webStyle.zoomButton}>{Math.round(this.state.zoomLevel*100)}%</span>
                    <button  style={webStyle.zoomButton} disabled={this.state.isSaving} onClick={this.zoomIn}>+</button>
                    <button style={webStyle.zoomButton} disabled={this.state.isSaving} onClick={this.resetZoom}><img src={zoom} alt="zoom" width={23} height={23}/></button>
                  </div>)}
                  <Prompt
                    when={!this.state.isSaved}
                    message="You have unsaved changes. Are you sure you want to leave?"
                  />
                  <Prompt
                    when={this.state.isSaving}
                    message="Saving in progress. Are you sure you want to leave?"
                  />
                  <Snackbar
                    open={this.state.openPrompt}
                    autoHideDuration={5000}
                    onClose={this.handleClosePrompt}
                  >
                  <Alert
                    onClose={this.handleClosePrompt}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                    Changes saved successfully
                  </Alert>
                  </Snackbar>
                  <Snackbar
                    open={this.state.openErrorPrompt}
                    autoHideDuration={5000}
                    onClose={this.handleCloseErrorPrompt}
                  >
                  <Alert
                    onClose={this.handleCloseErrorPrompt}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                    {this.state.errorMessage}
                  </Alert>
                  </Snackbar>
                </div>
              </div>
            </div >
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MenuTooltip = withStyles({
  tooltip: {
    padding: "0 0 !important",
    backgroundColor: "unset !important",
    color: "black !important",
  }
})(Tooltip);

const CustomBadge = styled(Badge)({
  '& .MuiBadge-dot': {
    backgroundColor: '#6200EA',
    minWidth: '5px',
    width: '5px',
    height: '5px'
  },
})
const webStyle = {
  whiteboardHeader: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '1rem'
  },
  container: {
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%',
    height: '100vh',
    fontFamily: 'Inter',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipBody: {
    minWidth: '120px',
    maxWidth: 'fit-content',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    gap: '24px',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  tool: {
    width: '50px',
    height: '50px',
    backgroundColor: '#FFF',
    border: 'none',
    borderRadius: 'none',
    transition: 'background-color 0.3s ease',
  },
  zoomButton: {
    backgroundColor: '#FFF',
    border: 'none',
    borderRadius: 'none',
    color: '#676B7E',
    fontFamily: "'Rubik', sans-serif",
    fontWeight: 500,
    fontSize: '14px',
    margin: '0px 5px'
  },
  toolHover: {
    backgroundColor: '#CBD5E1',
  },
  toolbar: {
    position: 'fixed',
    top: 200,
    right: 20,
    padding: 5,
    width: '60px',
    height: '200px',
    borderRadius: '14px',
    zIndex: 99999
    
  },
  zoombar: {
    position: 'fixed',
    backgroundColor: '#FFF',
    bottom: 20,
    left: 250,
    padding: 5,
    height: '50px',
    width: '200px',
    borderRadius: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 25px 50px rgba(0, 0, 0, 0.09)'
  },
  mainWrapper: {
    fontFamily: "'Rubik', sans-serif",
    justifyContent: 'center',
    blur: "100px",
    width: "auto",
    height: "auto",
    display: "flex",
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%)',
  },
  secondWrapper: {
    marginTop: '10px',
    width: "100%",
    height: '100%',
  },
  flexdisplay: {
    padding: "30px",
    overflow: 'hidden',
    height: '80vh'
  },
} as const;
// Customizable Area End
