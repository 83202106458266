export const natashaIcon = require("../assets/group_shape.png");
export const personIcon = require("../assets/avatar_icon_person.png")
export const image_check = require("../assets/image-check.svg")
export const drop_down_icon = require("../assets/drop-down-icon.svg")
export const country = require("../assets/country.svg")
export const newest_filter = require("../assets/newest-filter.svg")
export const calendar_Icon = require("../assets/calendar.svg")
export const rectangle = require("../assets/rectangle.svg")
export const line = require("../assets/line.svg")
export const zoom = require("../assets/zoom.svg")
export const copy = require("../assets/copy.svg")
export const arrow = require("../assets/arrow.svg")
export const filter_Icon =require("../assets/filterIcon.png");
export const arrow2 = require("../assets/arrow2.svg")
/* istanbul ignore next */export const add_blue_icon = require("../assets/add-blue-icon.svg")
export const delete_icon  = require("../assets/delete.svg")
